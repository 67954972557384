import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
} from '../styles';
import { ArrowIcon } from './ui';

const StyledLinkList = styled.section`
  padding: 20px;
  color: ${standardColours.white};
  background-color: ${brandColours.slate[500]};

  ${minBreakpointQuery.large`
    padding: 25px;
  `}
`;

const StyledHeading = styled.h2`
  ${fontSize(24)};

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledList = styled.ul``;

const StyledItem = styled.li`
  margin-top: 10px;

  ${minBreakpointQuery.small`
    margin-top: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 18px;
  `}
`;

const StyledLink = styled(OutboundLink)`
  position: relative;
  display: block;
  padding: 3px 0 3px 30px;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
  `}
`;

const StyledIcon = styled(ArrowIcon)`
  position: absolute;
  top: 6px;
  left: 0;
  fill: ${standardColours.white};

  ${minBreakpointQuery.small`
    top: 8px;
  `}

  ${minBreakpointQuery.large`
    top: 10px;
  `}
`;

const LinkList = ({ heading, items }) =>
  items.length > 0 && (
    <StyledLinkList>
      <StyledHeading>{heading}</StyledHeading>
      <StyledList>
        {items.map(({ id, title, externalLink }) => (
          <StyledItem key={id}>
            <StyledLink href={externalLink} target="_blank" rel="noopener">
              <StyledIcon />
              {title}
            </StyledLink>
          </StyledItem>
        ))}
      </StyledList>
    </StyledLinkList>
  );

export default LinkList;
