import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
} from '../styles';
import { Button } from './ui';

const StyledCareersCta = styled.section`
  padding: 20px;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.large`
    padding: 25px;
  `}
`;

const StyledHeading = styled.h2`
  color: ${brandColours.slate[600]};
  ${fontSize(26)};

  ${minBreakpointQuery.small`
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledText = styled.p`
  margin: 12px 0;
  color: ${brandColours.slate[600]};

  ${minBreakpointQuery.small`
    margin-top: 16px;
    margin-bottom: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
    margin-bottom: 20px;
  `}
`;

const CareersCta = () => {
  const {
    datoCmsCareersCta: { heading, text },
  } = useStaticQuery(graphql`
    query CareersCtaQuery {
      datoCmsCareersCta {
        heading
        text
      }
    }
  `);

  return (
    <StyledCareersCta>
      <StyledHeading>{heading}</StyledHeading>
      <StyledText>{text}</StyledText>
      <Button to="careers">See open positions</Button>
    </StyledCareersCta>
  );
};

export default CareersCta;
