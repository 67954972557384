import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontSize,
} from '../styles';

const StyledTeamCards = styled.section`
  margin: 30px 0;

  ${minBreakpointQuery.large`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  ${minBreakpointQuery.tiny`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.tsmall`
    gap: 30px;
  `}

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    gap: 40px;
  `}
`;

const StyledItem = styled.li``;

const StyledImage = styled(GatsbyImage)`
  border-radius: 50%;
`;

const StyledNameRole = styled.p`
  margin: 12px 0;
  color: ${standardColours.white};
  ${fontSize(15)};
  text-align: center;

  ${minBreakpointQuery.medium`
    ${fontSize(17)};
  `}

  span {
    ${brandFonts.futuraPTDemi()};
  }
`;

const TeamCards = ({ items }) =>
  items.length > 0 && (
    <StyledTeamCards>
      <StyledList>
        {items.map(({ id, image: { gatsbyImageData, alt }, name, role }) => (
          <StyledItem key={id}>
            <StyledImage image={gatsbyImageData} alt={alt} />
            <StyledNameRole>
              <span>{name}</span>, {role}
            </StyledNameRole>
          </StyledItem>
        ))}
      </StyledList>
    </StyledTeamCards>
  );

export default TeamCards;
