import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import {
  Container,
  GridTwoColumnsRight,
  GridMain,
  GridSidebar,
  HtmlContent,
} from '../components/ui';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import TeamCards from '../components/TeamCards';
import LinkList from '../components/LinkList';
import CareersCta from '../components/CareersCta';
import TestimonialCard from '../components/TestimonialCard';
import ModularBlocks from '../components/ModularBlocks';

const DepartmentPageTemplate = ({
  data: {
    datoCmsDepartment: {
      seoMetaTags,
      title,
      bannerText,
      content,
      team,
      testimonialImage,
      testimonialQuote,
      testimonialName,
      testimonialRole,
      modularBlocks,
    },
    allDatoCmsCareer: { careers },
  },
}) => (
  <Layout altTheme={true}>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        overline="Departments"
        heading={title}
        text={bannerText}
        backLink={{ slug: 'departments', text: 'Back to Departments' }}
        altTheme={true}
      />
      <Container>
        <GridTwoColumnsRight>
          <GridMain>
            <HtmlContent html={content} altTheme={true} />
            <TeamCards items={team} />
          </GridMain>
          <GridSidebar>
            <LinkList heading="Open positions" items={careers} />
            <CareersCta />
            <TestimonialCard
              image={testimonialImage}
              quote={testimonialQuote}
              name={testimonialName}
              role={testimonialRole}
            />
          </GridSidebar>
        </GridTwoColumnsRight>
      </Container>
      <ModularBlocks items={modularBlocks} altTheme={true} />
    </main>
  </Layout>
);

export const DepartmentPageTemplateQuery = graphql`
  query DepartmentPageTemplateQuery($id: String!) {
    datoCmsDepartment(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      content
      team {
        id
        image {
          gatsbyImageData(width: 240, height: 240)
          alt
        }
        name
        role
      }
      testimonialImage {
        gatsbyImageData(width: 380, height: 380)
        alt
      }
      testimonialQuote
      testimonialName
      testimonialRole
      modularBlocks {
        ...ContentModularBlockFragment
        ...ImageCarouselModularBlockFragment
      }
    }
    allDatoCmsCareer(filter: { department: { id: { eq: $id } } }) {
      careers: nodes {
        id
        title
        externalLink
      }
    }
  }
`;

export default DepartmentPageTemplate;
